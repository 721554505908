import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import $ from 'jquery';
import { AppService } from './app.service';
import { CONST_AWS_API, CONST_ONE_API, COUNTRIES_LIST, DEFAULT_IMAGE } from '../constants';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import * as moment from "moment";
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { API as AWS_API } from 'aws-amplify';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';


declare let document: any;
declare let google: any;
declare var fbq: any;
declare let gtag: any;

@Injectable({
  providedIn: 'root'
})
export class CommanSubscribeService {
  // setLeftTabActiveBasedOnRouterURL(arg0: string) {
  //   throw new Error("Method not implemented.");
  // }

  userInfo: any = {};
  searchInfo: any = {};
  paymentInfo: any = {};
  locationInfo: any = {};
  streamInfo: any;
  expoInfo: any;
  slugInfo: any;
  ticketInfo: any;
  contactInfo: any;
  deviceInfo: string = '';
  currencyInfo: any;
  subEventsInfo: any;
  upcomingEventInfo: any;
  myListInfo: any;
  streamStatusInfo: any;
  ip: any = null;
  icWebpageSettingsData: any = {};
  mediaPlayerData: any = {};
  perfomerInfoData: any = {};
  subscribePayInfoData: any;
  subscribePayedInfoData: any;
  albumBackHistory: any = "";
  deviceType = "browser";
  musicPlayerData: any;
  trackingCodeInfo: any;
  searchVal: any;
  // userStatus:boolean = false;
  private userInfoSubject = new Subject<any>();
  private subjectChangeMenuItem = new Subject<any>();
  private searchInfoSubject = new Subject<any>();
  private expoInfoSubject = new Subject<any>();
  private slugInfoSubject = new Subject<any>();
  private paymentInfoSubject = new Subject<any>();
  private streamInfoSubject = new Subject<any>();
  private subEventInfoSubject = new Subject<any>();
  private ticketInfoSubject = new Subject<any>();
  private HLSPlayVideoSubject = new Subject<any>();
  private deviceInfoSubject = new Subject<any>();
  private deviceAppSubject = new Subject<any>();
  private contactInfoSubject = new Subject<any>();
  private currencyInfoSubject = new Subject<any>();
  private screenStatusSubject = new Subject<any>();
  private upcomingEventInfoSubject = new Subject<any>();
  private myListInfoSubject = new Subject<any>();
  private streamStatusInfoSubject = new Subject<any>();
  private paymentStatusSubject = new Subject<any>();
  private icWebpageSettingsSubject = new Subject<any>();
  private perfomerInfoDataSubject = new Subject<any>();
  private subscribePayDataSubject = new Subject<any>();
  private subscribePayedDataSubject = new Subject<any>();
  private musicPlayerDataSubject = new Subject<any>();

  private mediaPlayerDataSubject = new Subject<any>();
  private trackingCodeInfoSubject = new Subject<any>();
  private searchValSubject = new Subject<any>();
  private albumStatusSUbject = new Subject<any>();

  signUpInfo: any = {};
  signUpInfoSubject = new Subject<any>();
  eventSlugDataSubject = new Subject<any>();
  eventSlugData: any = {};
  returnUrl: any = "";
  // userStatusSubject = new Subject<any>();
  ContactDataSubject = new Subject<any>();
  contactsData: any;
  loginStatusInfo: any
  loginStatusInfoSubject = new Subject<any>();
  cartProductsSubject = new Subject<any>();
  showActiveProducts = new Subject<any>();
  currencyRatesSubject = new Subject<any>();
  checkoutTimer = new Subject<any>();
  selectedCardSubject = new Subject<any>();
  addressListSubject = new Subject<any>();
  savedCardsSubject = new Subject<any>();
  performerStatusSubject = new Subject<any>();
  albumStatusOpened = "";
  GetcollectionData = [];
  CollectionSubject = new Subject<any>();

  languages = [
    { code: 'en', name: "English", lang: "English" },
    { code: 'zh-CN', name: 'Chinese (Simplified)', lang: "简体中文" },
    { code: 'zh-TW', name: 'Chinese (Traditional)', lang: "中國傳統的" },
    { code: 'hi', name: "Hindi", lang: "हिंदी" },
    { code: 'id', name: "Indonesian", lang: "bahasa Indonesia" },
    { code: 'ur', name: "Urdu", lang: "اردو" },
    { code: 'pt', name: "Portuguese (Portugal, Brazil)", lang: "Português (Portugal, Brasil)" },
    { code: 'bn', name: "Bengali", lang: "বাংলা" },
    { code: 'ru', name: "Russian", lang: "русский" },
    { code: 'es', name: "Spanish", lang: "español" },
    { code: 'ja', name: "Japanese", lang: "日本" },
    { code: 'tl', name: "Tagalog (Filipino)", lang: "Tagalog (Filipino)" },
    { code: 'am', name: "Amharic", lang: "አማርኛ" },
    { code: 'ar', name: "Arabic", lang: "عربى" },
    { code: 'fr', name: "French", lang: "français" },
    { code: 'vi', name: "Vietnamese", lang: "Tiếng Việt" },
    { code: 'fa', name: "Persian", lang: "فارسی" },
    { code: 'tr', name: "Turkish", lang: "Türk" },
    { code: 'de', name: "German", lang: "Deutsche" },
    { code: 'th', name: "Thai", lang: "ไทย" },


    // { code: 'af', name: "Afrikaans" }, 
    // { code: 'sq', name: "Albanian" }, 
    // { code: 'am', name: "Amharic" }, 
    // { code: 'ar', name: "Arabic" }, 
    // { code: 'hy', name: "Armenian" }, 
    // { code: 'az', name: "Azerbaijani" }, 
    // { code: 'eu', name: "Basque" }, 
    // { code: 'be', name: "Belarusian" }, 
    // { code: 'bn', name: "Bengali" }, 
    // { code: 'bs', name: "Bosnian" }, 
    // { code: 'bg', name: "Bulgarian" }, 
    // { code: 'ca', name: "Catalan" }, 
    // { code: 'ceb ', name: "Cebuano" }, 
    // { code: 'zh-CN', name: 'Chinese (Simplified)' }, 
    // { code: 'zh-TW', name: 'Chinese (Traditional)' },
    // { code: 'co', name: "Corsican" }, 
    // { code: 'hr', name: "Croatian" }, 
    // { code: 'cs', name: "Czech" }, 
    // { code: 'da', name: "Danish" }, 
    // { code: 'nl', name: "Dutch" }, 
    // { code: 'en', name: "English" }, 
    // { code: 'eo', name: "Esperanto" }, 
    // { code: 'et', name: "Estonian" }, 
    // { code: 'fi', name: "Finnish" }, 
    // { code: 'fr', name: "French" }, 
    // { code: 'fy', name: "Frisian" }, 
    // { code: 'gl', name: "Galician" }, 
    // { code: 'ka', name: "Georgian" }, 
    // { code: 'de', name: "German" }, 
    // { code: 'el', name: "Greek" }, 
    // { code: 'gu', name: "Gujarati" }, 
    // { code: 'ht', name: "Haitian Creole	" }, 
    // { code: 'ha', name: "Hausa" }, 
    // { code: 'haw', name: "Hawaiian" }, 
    // { code: 'he', name: "Hebrew" }, 
    // { code: 'hi', name: "Hindi" },
    // { code: 'hmn', name: "Hmong" }, 
    // { code: 'hu', name: "Hungarian" }, 
    // { code: 'is', name: "Icelandic" }, 
    // { code: 'ig', name: "Igbo" }, 
    // { code: 'id', name: "Indonesian" }, 
    // { code: 'ga', name: "Irish" }, 
    // { code: 'it', name: "Italian" }, 
    // { code: 'ja', name: "Japanese" }, 
    // { code: 'jv', name: "Javanese" }, 
    // { code: 'kn', name: "Kannada" }, 
    // { code: 'kk', name: "Kazakh" }, 
    // { code: 'km', name: "Khmer" }, 
    // { code: 'rw', name: "Kinyarwanda" }, 
    // { code: 'ko', name: "Korean" }, 
    // { code: 'ku', name: "Kurdish" }, 
    // { code: 'ky', name: "Kyrgyz" }, 
    // { code: 'lo', name: "Lao" }, 
    // { code: 'lv', name: "Latvian" }, 
    // { code: 'lt', name: "Lithuanian" }, 
    // { code: 'lb', name: "Luxembourgish" }, 
    // { code: 'mk', name: "Macedonian" }, 
    // { code: 'mg', name: "Malagasy" }, 
    // { code: 'ms', name: "Malay" }, 
    // { code: 'ml', name: "Malayalam" }, 
    // { code: 'mt', name: "Maltese" }, 
    // { code: 'mi', name: "Maori" }, 
    // { code: 'mr', name: "Marathi" }, 
    // { code: 'mn', name: "Mongolian" }, 
    // { code: 'my', name: "Myanmar (Burmese)" }, 
    // { code: 'ne', name: "Nepali" }, 
    // { code: 'no', name: "Norwegian" }, 
    // { code: 'ny', name: "Nyanja (Chichewa)" }, 
    // { code: 'or', name: "Odia (Oriya)" }, 
    // { code: 'ps', name: "Pashto" }, 
    // { code: 'fa', name: "Persian" }, 
    // { code: 'pl', name: "Polish" }, 
    // { code: 'pt', name: "Portuguese (Portugal, Brazil)" }, 
    // { code: 'pa', name: "Punjabi" }, 
    // { code: 'ro', name: "Romanian" }, 
    // { code: 'ru', name: "Russian" }, 
    // { code: 'sm', name: "Samoan" }, 
    // { code: 'gd', name: "Scots Gaelic" }, 
    // { code: 'sr', name: "Serbian" }, 
    // { code: 'st', name: "Sesotho" }, 
    // { code: 'sn', name: "Shona" }, 
    // { code: 'sd', name: "Sindhi" }, 
    // { code: 'si', name: "Sinhala (Sinhalese)" }, 
    // { code: 'sk', name: "Slovak" }, 
    // { code: 'sl', name: "Slovenian" }, 
    // { code: 'so', name: "Somali" }, 
    // { code: 'es', name: "Spanish" }, 
    // { code: 'su', name: "Sundanese" }, 
    // { code: 'sw', name: "Swahili" }, 
    // { code: 'sv', name: "Swedish" }, 
    // { code: 'tl', name: "Tagalog (Filipino)" }, 
    // { code: 'tg', name: "Tajik" }, 
    // { code: 'ta', name: "Tamil" }, 
    // { code: 'tt', name: "Tatar" }, 
    // { code: 'te', name: "Telugu" }, 
    // { code: 'th', name: "Thai" }, 
    // { code: 'tr', name: "Turkish" }, 
    // { code: 'tk', name: "Turkmen" }, 
    // { code: 'uk', name: "Ukrainian" }, 
    // { code: 'ur', name: "Urdu" }, 
    // { code: 'ug', name: "Uyghur" }, 
    // { code: 'uz', name: "Uzbek" }, 
    // { code: 'vi', name: "Vietnamese" }, 
    // { code: 'cy', name: "Welsh" }, 
    // { code: 'xh', name: "Xhosa" }, 
    // { code: 'yi', name: "Yiddish" }, 
    // { code: 'yo', name: "Yoruba" }, 
    // { code: 'zu', name: "Zulu" }, 
  ];
  defaultSettingsData = {
    main_bg_color: "#22223D",
    bg_primary_color: "#070a0c",
    bg_secondary_color: "#8ce6d6",
    bg_tertiary_color: "#22223D",
    font_primary_color: "#ffffff",
    font_secondary_color: "#fff",
    font_tertiary_color: "#000000",
    fs_main_title: "20px",
    fs_sub_title: "16px",
    fs_sentence: "14px",
    card_shape: "round",
    font_family: "'Roboto', sans-serif",
    is_preview: false,
  };
  constructor(
    private storageService: StorageService,
    private appService: AppService, private http: HttpClient, private ngxLoader: NgxUiLoaderService, private router: Router) { }

  count = 0;
  sendCollectionData(data: any) {
    this.count + 1;
    console.log(this.count, "collections ")
    this.GetcollectionData.push(data);
    // this.CollectionSubject.next(data);
  }
  getCollectionData(): Observable<any> {
    return this.CollectionSubject.asObservable();
  }
  setLeftTabActiveBasedOnRouterURL(message: string) {
    this.subjectChangeMenuItem.next({ text: message });
  }
  getLeftTabActiveBasedOnRouterURL(): Observable<any> {
    return this.subjectChangeMenuItem.asObservable();
  }
  sendUserInfo(data: any) {
    this.userInfo = data;
    this.userInfoSubject.next(data);
  }
  getUserInfo(): Observable<any> {
    return this.userInfoSubject.asObservable();
  }

  sendSignUpInfo(data: any) {
    this.signUpInfo = data;
    this.signUpInfoSubject.next(data);
  }

  getSingUpInfo(): Observable<any> {
    return this.signUpInfoSubject.asObservable();
  }
  sendSearchInfo(data: any) {
    this.searchInfo = data;
    this.searchInfoSubject.next(data);
  }

  getSearchInfo(): Observable<any> {
    return this.searchInfoSubject.asObservable();
  }

  sendPaymentInfo(data: any) {
    this.paymentInfo = data;
    this.paymentInfoSubject.next(data);
  }
  getPaymentInfo(): Observable<any> {
    return this.paymentInfoSubject.asObservable();
  }

  sendStreamInfo(data: any) {
    this.streamInfo = data;
    this.streamInfoSubject.next(data);
  }
  getStreamInfo(): Observable<any> {
    return this.streamInfoSubject.asObservable();
  }

  sendSubEventsInfo(data: any) {
    this.subEventsInfo = data;
    this.subEventInfoSubject.next(data);
  }
  getSubEventsInfo(): Observable<any> {
    return this.subEventInfoSubject.asObservable();
  }

  sendExpoDetailsToHeader(data: any) {
    this.expoInfo = data;
    this.expoInfoSubject.next(data);
  }
  getExpoInfo(): Observable<any> {
    return this.expoInfoSubject.asObservable();
  }

  sendSlugData(data: any) {
    this.slugInfo = data;
    this.slugInfoSubject.next(data);
  }
  getSlugData(): Observable<any> {
    return this.slugInfoSubject.asObservable();
  }

  sendContactInfo(data: any) {
    this.contactsData = data;
    this.ContactDataSubject.next(data);
  }
  getContactInfo(): Observable<any> {
    return this.ContactDataSubject.asObservable();
  }
  sendTicketDetails(data: any) {
    this.ticketInfo = data;
    this.ticketInfoSubject.next(data);
  }
  getTicketDetails(): Observable<any> {
    return this.ticketInfoSubject.asObservable();
  }

  sendContactDetails(data: any) {
    this.contactInfo = data;
    this.contactInfoSubject.next(data);
  }
  getContactDetails(): Observable<any> {
    return this.contactInfoSubject.asObservable();
  }

  sendUpcomingEvents(data: any) {
    this.upcomingEventInfo = data;
    this.upcomingEventInfoSubject.next(data);
  }
  getUpcomingEvents(): Observable<any> {
    return this.upcomingEventInfoSubject.asObservable();
  }
  sendMylistData(data: any) {
    this.myListInfo = data;
    this.myListInfoSubject.next(data);
  }
  getMylistData(): Observable<any> {
    return this.myListInfoSubject.asObservable();
  }

  // sendUserStatus(data: any) {
  //   this.userStatus = data;
  //   this.userStatusSubject.next(data);
  // }

  // getUserStatus(): Observable<any> {
  //   return this.userStatusSubject.asObservable();
  // }

  sendEventSlugData(data: any) {
    this.eventSlugData = data;
    this.eventSlugDataSubject.next(data);
  }
  getEventSlugData(): Observable<any> {
    return this.eventSlugDataSubject.asObservable();
  }

  sendPlayHLSVideo(data: any) {
    this.HLSPlayVideoSubject.next(data);
  }
  getPlayHLSVideo(): Observable<any> {
    return this.HLSPlayVideoSubject.asObservable();
  }

  sendDeviceType(data: any) {
    this.deviceInfo = data;
    this.deviceInfoSubject.next(data);
  }
  getDeviceType(): Observable<any> {
    return this.deviceInfoSubject.asObservable();
  }
  sendAppType(data: any) {
    this.deviceType = data;
    this.deviceAppSubject.next(data);
  }
  getAppType(): Observable<any> {
    return this.deviceAppSubject.asObservable();
  }
  sendICWebpageThemeSettings(data: any) {
    this.icWebpageSettingsData = data;
    this.icWebpageSettingsSubject.next(data);
  }
  getICWebpageThemeSettings(): Observable<any> {
    return this.icWebpageSettingsSubject.asObservable();
  }

  sendMediaPlayerData(data: any) {
    if (data.type != "function") {
      if (data.key == "musicList") {
        this.mediaPlayerData.musicList = data.value;
      } else if (data.key == "subscribedUserPlans") {
        this.mediaPlayerData.subscribedUserPlans = data.value;
      } else if (data.key == "is_show_musicPlayer") {
        this.mediaPlayerData.is_show_musicPlayer = data.value;
      }
    }
    this.mediaPlayerDataSubject.next(data);
  }
  getMediaPlayerData(): Observable<any> {
    return this.mediaPlayerDataSubject.asObservable();
  }

  sendPerfomerInfo(data: any) {
    this.perfomerInfoData = data;
    this.perfomerInfoDataSubject.next(data);
  }
  getPerfomerInfo(): Observable<any> {
    return this.perfomerInfoDataSubject.asObservable();
  }
  sendSubscriberPayedInfo(data: any) {
    this.subscribePayedInfoData = data;
    if (this.subscribePayInfoData && this.subscribePayInfoData.data) {
      this.subscribePayInfoData.data.Subscription_details = data;
    }
    if (this.perfomerInfoData && this.perfomerInfoData.data) {
      this.perfomerInfoData.data.Subscription_details = data;
    }
    this.subscribePayedDataSubject.next(data);
  }
  getSubscribePayedInfo(): Observable<any> {
    return this.subscribePayedDataSubject.asObservable();
  }
  sendSubscribePayInfo(data: any) {
    this.subscribePayInfoData = data;
    this.subscribePayDataSubject.next(data);
  }
  getSubscribePayInfo(): Observable<any> {
    return this.subscribePayDataSubject.asObservable();
  }

  sendCurrentMusicData(data: any) {
    this.musicPlayerData = data;
    this.musicPlayerDataSubject.next(data);
  }
  getCurrentMusicData(): Observable<any> {
    return this.musicPlayerDataSubject.asObservable();
  }

  sendSearchKey(data: any) {
    this.searchVal = data;
    this.searchValSubject.next(data);
  }
  getSearchKey(): Observable<any> {
    return this.searchValSubject.asObservable();
  }

  getPerformerStatus(): Observable<any> {
    return this.performerStatusSubject.asObservable();
  }
  sendAlbumStatus(data) {
    this.albumStatusOpened = data;
    this.albumStatusSUbject.next(data);
  }
  getAlbumStatus(): Observable<any> {
    return this.albumStatusSUbject.asObservable();
  }
  fontFamilyList = [
    { id: "1", lable: "Roboto", link: "https://fonts.googleapis.com/css2?family=Roboto&display=swap", code: "'Roboto', sans-serif" },
    { id: "2", lable: "Poppins", link: "https://fonts.googleapis.com/css2?family=Poppins&display=swap", code: "'Poppins', sans-serif" },
    { id: "3", lable: "Open Sans", link: "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap", code: "'Open Sans', sans-serif" },
    { id: "4", lable: "Raleway", link: "https://fonts.googleapis.com/css2?family=Raleway&display=swap", code: "'Raleway', sans-serif" },
    { id: "5", lable: "Ubuntu", link: "https://fonts.googleapis.com/css2?family=Ubuntu&display=swap", code: "'Ubuntu', sans-serif" },
    { id: "6", lable: "PT Sans", link: "https://fonts.googleapis.com/css2?family=PT+Sans&display=swap", code: "'PT Sans', sans-serif" },
  ];

  getFilterMenu(menuList, url, type?) {
    let menus = JSON.parse(JSON.stringify(menuList));
    let menuLink = url;
    if (url && url.includes('-')) {
      menuLink = url.replace(/-/g, ' ');
    }
    if (type && type == 'subMenu') {
      menus.forEach(val => {
        if (val.sub_menu.length > 0) {
          val.sub_menu.forEach(sublist => {
            sublist.subMenuActive = true;
            if (sublist.link == url) {
              sublist.subMenuActive = true;
            } else {
              sublist.subMenuActive = false;
            }
          });
        }
      });
    } else if (url && type && type == 'allMenu') {
      menus.forEach(val => {
        if (val.link) {
          if (`/${val.link}` == url) {
            val.activeMenu = true;
          } else {
            val.activeMenu = false;
          }
        } else if (!val.link && val.sub_menu.length > 0) {
          val.activeMenuList = false;
          val.sub_menu.forEach(m => {
            if (`/${m.link}` == url) {
              val.activeMenuList = true;
              m.subMenuActive = true;
            } else {
              m.subMenuActive = false;
            }
          });
        }
      });
    } else {
      menus.forEach(val => {
        val.activeMenu = true;
        if (val.sub_menu.length == 0) {
          if ((menuLink && menuLink.includes(val.name.toLowerCase())) || (url && url.includes(val.link.toLowerCase()))) {
            val.activeMenu = true;
          } else {
            val.activeMenu = false;
          }
        } else {
          if ((menuLink && menuLink.includes(val.name.toLowerCase())) || (url && url.includes(val.name.toLowerCase()))) {
            val.activeMenuList = true;
            val.sub_menu[0].subMenuActive = true;
          } else if (!url && type && val.name == type.name) {
            val.activeMenuList = true;
            val.sub_menu[0].subMenuActive = true;
          } else {
            val.activeMenuList = false;
          }
        }
      });
    }
    return menus;
  }



  initSlider(sliderClass) {
    $(document).ready(function () {
      // $(sliderClass).on('init', () => {
      //   $(sliderClass + " .slick-slide").css({
      //     visibility: 'visible'
      //   });
      // });
      $(sliderClass).slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        autoplaySpeed: 1500,
        arrows: true,
        dots: false,
        pauseOnHover: false,
        infinite: false,
        prevArrow: '<div class="Slider-left-arrow pointer"><i class="fa fa-chevron-left" style="font-size: 24px;" aria-hidden="true"></i><div>',
        nextArrow: '<div class="Slider-right-arrow pointer"><i class="fa fa-chevron-right" style="font-size: 24px;" aria-hidden="true"></i></div>',
        responsive: [{
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }]
      });
      // $('.channels-images-slider2').show();
    });
  }

  // getAddress(address, callback) {
  //   let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + "&key=" + environment.mapKey + "&language=en";
  //   fetch(url)
  //     .then((res) => {
  //       return res.json();
  //     }).then((json) => {
  //       //console.log(json);
  //       callback(json);
  //     }).catch(error => {
  //       console.log(error);
  //       callback([]);
  //     });
  // }

  // validateZip(zip, country, callback) {
  //   let find = COUNTRIES_LIST.filter(con => con.name == country);
  //   find = find[0] ? find[0] : {};
  //   let codes: any = { state: '', country: country, country_code: find.alpha2code, status: false };
  //   if (find.validation != "yes") {
  //     codes.country = find.alpha2code;
  //     codes.country_name = country;
  //     codes.status = true;
  //     callback(codes);
  //   } else {

  //     let url = "https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:" + zip + "|country:" + country + "&key=" + environment.mapKey;
  //     fetch(url)
  //       .then((res) => {
  //         return res.json();
  //       }).then((json) => {
  //         //console.log(json);
  //         if (json.results.length > 0) {
  //           for (var i = 0; i < json.results[0].address_components.length; i++) {
  //             if (json.results[0].address_components[i].types.indexOf('country') != -1) {
  //               // if (n == 3) {
  //               //     codes.country = getCountryISO3(json.results[0].address_components[i].short_name);
  //               // } else {
  //               codes.country = json.results[0].address_components[i].short_name;
  //               // }
  //               codes.country_name = json.results[0].address_components[i].long_name;
  //             }
  //             if (json.results[0].address_components[i].types.indexOf('administrative_area_level_1') != -1) {
  //               codes.state = json.results[0].address_components[i].short_name;
  //             }
  //           }
  //           codes.status = true;
  //         }

  //         callback(codes);
  //       }).catch(error => {
  //         console.log(error);
  //         callback(codes);
  //       });
  //   }
  // }

  //one login auth
  getAuthToken() {
    let reqData = {
      "grant_type": "client_credentials"
    }
    this.appService.OL_PostRequest(CONST_ONE_API.GET_TOKEN, reqData).subscribe(result => {
      // result.generated_time = new Date();
      this.storageService.setItem("ol_authInfo", result);
    }, error => {
    });
  }

  checkExpire_Token() {
    let ol_authInfo = this.storageService.getItem("ol_authInfo");
    let refreshToken = false;
    if (ol_authInfo && ol_authInfo.access_token) {

      let created_at = ol_authInfo.created_at;
      let between_second = (new Date().getTime() - new Date(created_at).getTime()) / 1000;

      let created_at_1 = moment(ol_authInfo.created_at).format("DD MMM YYYY, hh:mm A");
      let UTC_time_1 = moment(this.convertInToUTC(new Date())).format("DD MMM YYYY, hh:mm A");
      //console.log("Access Token", created_at_1, UTC_time_1);
      let between_second_1 = (new Date(UTC_time_1).getTime() - new Date(created_at_1).getTime()) / 1000;

      if (between_second > ol_authInfo.expires_in) {
        refreshToken = true;
        return refreshToken;
      } else if (between_second_1 > ol_authInfo.expires_in) {
        refreshToken = true;
        return refreshToken;
      } else {
        refreshToken = false;
        return refreshToken;
      }
    } else {
      refreshToken = true;
      return refreshToken;
    }
  }

  convertInToUTC(date1) {
    var date = new Date(date1);
    var year: any = date.getUTCFullYear();
    var month: any = date.getUTCMonth() + 1;
    var day: any = date.getUTCDate();
    var hours: any = date.getUTCHours();
    var minutes: any = date.getUTCMinutes();
    var seconds: any = date.getUTCSeconds();
    month = (month < 10) ? '0' + month : month;
    day = (day < 10) ? '0' + day : day;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    return new Date(year + '-' + month + '-' + day + ' ' + hours + ':' + minutes);
  }

  getAmount(streamInfo, currentLocation, isSubscriber, ticketsData?) {
    let amounts;
    // ticketsData.amounts = typeof ticketsData.amounts == "string" ? JSON.parse(ticketsData.amounts) : ticketsData.amounts ? ticketsData.amounts : {};

    let currentTime = moment.utc();
    if (streamInfo.current_time) {
      currentTime = moment(streamInfo.current_time);
    }
    let result: any = {};
    amounts = amounts || [];
    let currectDatesAmounts = [];
    let futureDateAmounts = [];
    let ticketsPrice = [];
    let minPrice = 0, maxPrice = 0;
    let isSaleIsOn = false;
    ticketsData.forEach(element => {
      if (element.amounts) {
        // element.amounts = JSON.parse(element.amounts);
        // element.amounts.forEach(date => {
        let cStart = moment.utc(element.start_date);
        let cEnd = moment.utc(element.end_date.replace("00:00", "23:59"));
        let current = this.getPriceWithCurrency(element.amounts, currentLocation, isSubscriber, streamInfo);
        if (current) {
          currectDatesAmounts.push(element);
          element.current = current;
        }
        //console.log('ticket', currectDatesAmounts);
        if (currentTime >= cStart && currentTime <= cEnd) { //here will get only currect date amount
          isSaleIsOn = true;
          element.current = current;
          // currectDatesAmounts.push(element);
          if (minPrice == 0) {
            minPrice = current.price;
          }
          if (maxPrice == 0) {
            maxPrice = current.price;
          }
          if (minPrice >= current.price) {
            minPrice = current.price;
            result.minPrice = current.price;
            result.minCurrency = current.currency;
          }
          if (maxPrice <= current.price) {
            maxPrice = current.price;
            result.maxPrice = current.price;
            result.maxCurrency = current.currency
          }
        } else if (currentTime <= cStart && currentTime <= cEnd) {
          futureDateAmounts.push(element);
        }
        // });
        result.currectDatesAmounts = currectDatesAmounts;
        //console.log('fff', result);
      }
    });


    if (!isSaleIsOn) {
      if (futureDateAmounts.length == 0) {
        result.message = "Sale is completed!";
      } else if (futureDateAmounts.length > 0) {
        // let offset = (new Date().getTimezoneOffset() * -1) + timezone_offset;
        let offset = (new Date().getTimezoneOffset() * -1);
        // if (offset == 0) {
        let saleDate = moment(futureDateAmounts[0].booking_start_date);
        result.message = "Sale Starts On " + saleDate.format('DD MMM YYYY');
        // } else {
        //   let saleDate = moment(futureDateAmounts[0].booking_start_date).add(offset, 'minutes');
        //   result.message = "Sale Starts At " + saleDate.format('DD MMM YYYY');
        // }
      }
      if (streamInfo.stream_payment_mode == 'free') {
        let eventTime = moment.utc(streamInfo.publish_date_time);
        let currentUTC = moment.utc(streamInfo.current_time);
        if (currentUTC > eventTime) {
          result.message = "Sale is completed!";
        } else {
          result.message = '';
        }
      }
    } else {
      result.currectDatesAmounts = currectDatesAmounts;
    }

    return result;
  }

  // getAmount(streamInfo, currentLocation, isSubscriber, ticketsData) {
  //   let amounts;    
  //   streamInfo.stream_amounts = typeof streamInfo.stream_amounts =="string" ? JSON.parse(streamInfo.stream_amounts) : streamInfo.stream_amounts ? streamInfo.stream_amounts : {};
  //   let timezone_offset = streamInfo.stream_amounts.timezone_offset;
  //   if (streamInfo.stream_vod == "stream" && streamInfo.stream_status != "completed") {
  //     if (isSubscriber && streamInfo.stream_amounts.sub_live_stream  && streamInfo.stream_amounts.sub_live_stream.length > 0) {
  //       amounts = streamInfo.stream_amounts.sub_live_stream;
  //     } else {
  //       amounts = streamInfo.stream_amounts.live_stream;
  //     }
  //   } else {
  //     if (isSubscriber && streamInfo.stream_amounts.sub_vod  && streamInfo.stream_amounts.sub_vod.length > 0) {
  //       amounts = streamInfo.stream_amounts.sub_vod;
  //     } else {
  //       amounts = streamInfo.stream_amounts.vod;
  //     }
  //   }

  //   // let amounts = eventType == 'vod' ? streamInfo.stream_amounts.vod : streamInfo.stream_amounts.live_stream;
  //   let currentTime = moment.utc();
  //   // if (currentLocation.time_zone) {
  //   //   currentTime = moment.utc(currentLocation.time_zone.current_time);
  //   // }
  //   if (streamInfo.current_time) {
  //     currentTime = moment(streamInfo.current_time); 
  //   }

  //   let result: any = {};
  //   let start, end, priceChangeDate;
  //   amounts = amounts || [];

  //   let currectDatesAmounts = [];
  //   let futureDateAmounts = [];
  //   let minPrice = 0,maxPrice = 0;
  //   let isSaleIsOn = false;
  //   amounts.forEach(date => {
  //     let cStart = moment.utc(date.booking_start_date);
  //     let cEnd = moment.utc(date.booking_end_date.replace("00:00", "23:59"));
  //     let current = this.getPriceWithCurrency(date.amounts, currentLocation);
  //     if (currentTime >= cStart && currentTime <= cEnd) { //here will get only currect date amount
  //       isSaleIsOn = true;
  //       date.current = current;
  //       currectDatesAmounts.push(date);
  //       if(minPrice == 0) {
  //         minPrice = current.price;
  //       }
  //       if(maxPrice == 0) {
  //         maxPrice = current.price;
  //       }
  //       if(minPrice >= current.price) {
  //         minPrice = current.price;
  //       result.minPrice = current.price;
  //       result.minCurrency = current.currency;
  //     }
  //       if(maxPrice <= current.price) {
  //         maxPrice = current.price;
  //       result.maxPrice = current.price;
  //       result.maxCurrency = current.currency
  //     }
  //     } else if(currentTime <= cStart && currentTime <= cEnd) {
  //       futureDateAmounts.push(date);
  //     }
  //   });

  //   if(!isSaleIsOn) {
  //     if(futureDateAmounts.length == 0) {
  //       result.message = "Sale is completed!";
  //     } else if(futureDateAmounts.length > 0) {
  //       let offset = (new Date().getTimezoneOffset() * -1) + timezone_offset;
  //       if (offset == 0) {
  //         let saleDate =  moment(futureDateAmounts[0].booking_start_date);
  //         result.message = "Sale Starts On " + saleDate.format('DD MMM YYYY');
  //       } else {
  //         let saleDate =  moment(futureDateAmounts[0].booking_start_date).add(offset, 'minutes');
  //         result.message = "Sale Starts At " + saleDate.format('DD MMM YYYY, hh:mm A');
  //       }
  //     }
  //     // let ctDate = moment().format("YYYY-MM-DD");
  //     // let localTime = moment.utc(streamInfo.publish_date_time).toDate();
  //     // let eventPublishDate = moment(localTime).format('YYYY-MM-DD');
  //     // let currentDate = new Date(ctDate).getTime();
  //     // let eventDate = new Date(eventPublishDate).getTime();
  //     // if(streamInfo.stream_payment_mode == 'free' && currentDate < eventDate){
  //     //   let saleDate =  moment.utc(streamInfo.publish_date_time);
  //     //   result.message = "Sale Starts On " + saleDate.format('DD MMM YYYY');
  //     // }else if(streamInfo.stream_payment_mode == 'free' && currentDate == eventDate){
  //     //   result.message = '';
  //     // }else if(streamInfo.stream_payment_mode == 'free' && currentDate > eventDate){
  //     //   result.message = "Sale is completed!";
  //     // }

  //     if (streamInfo.stream_payment_mode == 'free') {
  //       let eventTime = moment.utc(streamInfo.publish_date_time);
  //       let currentUTC = moment.utc(streamInfo.current_time);
  //       if (currentUTC > eventTime) {
  //         result.message = "Sale is completed!";
  //       } else {
  //         result.message = '';
  //       }
  //     }
  //   } else {
  //     result.currectDatesAmounts = currectDatesAmounts;
  //   }

  //   // amounts.forEach(date => {
  //   //   let cStart = moment.utc(date.booking_start_date);
  //   //   let cEnd = moment.utc(date.booking_end_date.replace("00:00", "23:59"));
  //   //   let current = this.getPriceWithCurrency(date.amounts, currentLocation);
  //   //   if (start && !priceChangeDate && start < cStart) {
  //   //     priceChangeDate = cStart;
  //   //   }
  //   //   if (!start || start > cStart) {        
  //   //     start = cStart;
  //   //     result.minPrice = current.price;
  //   //     result.minCurrency = current.currency;
  //   //   }
  //   //   if (!end || end < cEnd) {
  //   //     end = cEnd;
  //   //     result.maxPrice = current.price;
  //   //     result.maxCurrency = current.currency
  //   //   }
  //   //   if (currentTime >= cStart && currentTime <= cEnd) {
  //   //     result.current = current;
  //   //   }
  //   // });
  //   // if (!result.current) {
  //   //   if (currentTime < start) {
  //   //     result.message = "Sale Starts On " + start.format('DD MMM YYYY');
  //   //   } else if (currentTime > end) {
  //   //     result.message = "Sale is completed!";
  //   //   }
  //   // } else if (priceChangeDate) {
  //   //   result.priceChangeDate = priceChangeDate.format('DD MMM YYYY');
  //   // }

  //   return result;
  // }
  getPriceWithCurrency(amounts, currentLocation, isSubscriber, streamInfo) {
    let dafaultAmount, defaultCurrency, amountSet, currency;
    let amountsInfo = typeof (amounts) == 'string' ? JSON.parse(amounts) : amounts;
    amountsInfo.forEach((amount) => {
      if (currentLocation && currentLocation.currency &&
        amount.currency_type == currentLocation.currency.code) {
        if (isSubscriber)
          amountSet = amount.subscriber;
        else
          amountSet = amount.non_subscriber;
        currency = currentLocation.currency.code;
      } else if (streamInfo.currency_type == amount.currency_type) {
        if (isSubscriber)
          dafaultAmount = amount.subscriber;
        else
          dafaultAmount = amount.non_subscriber;
        defaultCurrency = amount.currency_type;
      }
      // if (amount.country_name == "DEFAULT" || amount.is_default) {
      //   dafaultAmount = amount.stream_payment_amount;
      //   defaultCurrency = amount.currency_type;
      // }
    });
    let result = { price: 0, currency: "" };
    if (amountSet) {
      result.price = parseFloat(amountSet);
      result.currency = currency;
    } else {
      result.price = parseFloat(dafaultAmount);
      result.currency = defaultCurrency;
    }
    return result;
  }

  getStreamDate(streamInfo) {
    let localTime = moment.utc(streamInfo.publish_date_time).toDate();
    let time = moment(localTime).format('hh:mm A');
    let date = moment(localTime).format('ddd, MMM DD');
    let ctDate = moment().format("YYYY-MM-DD");
    let currentDate = new Date(ctDate).getTime();
    if (streamInfo.expected_end_date_time) {
      let endTime = moment.utc(streamInfo.expected_end_date_time).toDate();
      let eventEndDate = moment(endTime).format('YYYY-MM-DD');
      let expected_end_date_time = moment(endTime).format('hh:mm A');
      time += ' - ' + expected_end_date_time;
      let eventDate = new Date(eventEndDate).getTime();
      let currentMonth = moment(localTime).format('MMM')
      if (currentDate == eventDate) {
        date = moment(localTime).format('ddd, MMM DD YYYY');
      } else {
        let checkMonth = moment(endTime).format('MMM');
        if (checkMonth == currentMonth) {
          let end_date_time = moment(endTime).format('DD YYYY');
          let checkDate = date.split(' ');
          let endDate = end_date_time.split(' ');
          if (checkDate[2] == endDate[0])
            date = date + ' ' + new Date().getFullYear();
          else
            date += ' - ' + end_date_time;
        }
        else {
          let end_date_time = moment(endTime).format('MMM DD YYYY');
          date += ' - ' + end_date_time;
        }
      }
    }
    if (streamInfo.stream_vod == 'vod')
      date = moment(localTime).format('ddd, MMM DD YYYY');
    return { date: date, time: time };
  }

  checkIsSubscribePlan(subscription_details, plan_id) {
    let flag = false;
    if (subscription_details && subscription_details.length > 0) {
      subscription_details.forEach(e => {
        if (plan_id == e.plan_id && e.subscription_status != 0 && e.payment == 'success') {
          flag = true;
        }
      });
    } else {
      flag = false;
    }
    return flag;
  }

  checkIsSubscribeFailed(subscription_details, plan_id) {
    let flag = false;
    if (subscription_details && subscription_details.length > 0) {
      subscription_details.forEach(e => {
        if (plan_id == e.plan_id && e.payment == 'failed') {
          flag = true;
        }
      });
    } else {
      flag = false;
    }
    return flag;
  }

  checkIsSubscribePlanReActive(subscription_details, plan_id) {
    let flag = false;
    if (subscription_details && subscription_details.length > 0) {
      subscription_details.forEach(e => {
        if (plan_id == e.plan_id && e.subscription_status == 0 && new Date().getTime() < new Date(e.subscription_end_date).getTime()) {
          flag = true;
        }
      });
    } else {
      flag = false;
    }
    return flag;
  }

  getSubscribedAmount(subscription_details, data) {
    if (subscription_details && subscription_details.length > 0) {
      subscription_details.forEach(e => {
        if (data.id == e.plan_id) {
          data.subscription_amount = e.subscription_amount;
          data.currency = e.currency ? e.currency.toUpperCase() : "";
        }
      });
    }
  }

  // getCurrentLocation(callback: (status: any, val: any) => void) {
  //   if (!this.locationInfo || Object.keys(this.locationInfo).length == 0) {
  //     fetch("https://api.ipgeolocation.io/ipgeo?apiKey=" + environment.ipGeoKey)
  //       .then((res) => {
  //         return res.json();
  //       }).then((json) => {
  //         if (localStorage.currency) {
  //           json.currency.code = localStorage.currency
  //         }
  //         this.locationInfo = json;
  //         callback('success', json);
  //         //console.log("http://ip-api.com/json/", json);
  //       }).catch(error => {
  //         callback('fail', {});
  //       });
  //   } else {
  //     callback('success', this.locationInfo);
  //   }
  // }
  firstCharUpper(string) {
    string = string || "";
    return string.charAt(0).toUpperCase() + string.substring(1);
  }
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/windows phone/i.test(userAgent)) {
      return 'web';
    }
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return 'ios';
    }
    return 'web';
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    return this.scrollTo(firstElementWithError);
  }

  public getCurrenciesJSON(): Observable<any> {
    return this.http.get("");
  }

  sendCurrencyInfo(data: any) {
    this.currencyInfo = data;
    this.currencyInfoSubject.next(data);
  }
  getCurrencyInfo(): Observable<any> {
    return this.currencyInfoSubject.asObservable();
  }


  // checkscreenStaus()
  sendScreenStatus(data: any) {
    this.screenStatusSubject.next(data);
  }
  getScreenStatus(): Observable<any> {
    return this.screenStatusSubject.asObservable();
  }

  sendPaymentStatus(data: any) {
    this.paymentStatusSubject.next(data);
  }
  getPaymentStatus(): Observable<any> {
    return this.paymentStatusSubject.asObservable();
  }

  sendCurrentStreamStatus(data: any) {
    this.streamStatusInfo = data;
    this.streamStatusInfoSubject.next(data);
  }
  getCurrentStreamStatus(): Observable<any> {
    return this.streamStatusInfoSubject.asObservable();
  }

  sendTrackingCode(data: any) {
    this.trackingCodeInfo = data;
    this.trackingCodeInfoSubject.next(data);
  }
  getTrackingCode(): Observable<any> {
    return this.trackingCodeInfoSubject.asObservable();
  }

  sendLoginStatus(data: any) {
    this.loginStatusInfo = data;
    this.loginStatusInfoSubject.next(data);
  }
  getLoginStatus(): Observable<any> {
    return this.loginStatusInfoSubject.asObservable();
  }

  getActiveProductsList(): Observable<any> {
    return this.showActiveProducts.asObservable();
  }

  getCartProductsCount(): Observable<any> {
    return this.cartProductsSubject.asObservable();
  }

  getCurencyRates(): Observable<any> {
    return this.currencyRatesSubject.asObservable();
  }

  getCheckoutTimer(): Observable<any> {
    return this.checkoutTimer.asObservable();
  }

  getSelectedCardInfo(): Observable<any> {
    return this.selectedCardSubject.asObservable();
  }

  getAddressList(): Observable<any> {
    return this.addressListSubject.asObservable();
  }

  getSavedCards(): Observable<any> {
    return this.savedCardsSubject.asObservable();
  }

  googleTranslateElementInit() {
    let interval = setInterval(() => {
      if (typeof google != 'undefined') {
        new google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            // includedLanguages: 'es,en,zh-CN,ja',
            autoDisplay: false
          },
          'google_translate_element'
        );
        clearInterval(interval);
      }
    }, 1000);
  }

  countAdsImpressions(url) {
    fetch(url /*, options */)
      .then((response) => response.text())
      .then((html) => {
        // document.getElementById("content").innerHTML = html;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  setLangCookie(lang) {
    let expireDays = 1;
    let path = '/';
    let name = 'googtrans';
    let value = '/en/' + lang;
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  changeLanguage(lang) {
    if (lang == 'en-US' || lang == 'en-GB') {
      lang = 'en';
    }
    this.storageService.setItem('lang', lang);
    let element = document.getElementsByClassName('goog-te-combo')[0];
    element['value'] = lang;
    //console.log(lang);
    var event;
    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      event.initEvent('change', true, true);
      element.dispatchEvent(event);
    } else {
      event = document.createEventObject();
      event.eventType = 'change';
      element.fireEvent('on' + event.eventType, event);
    }
  }

  getLanguage() {
    let lang = 'en';
    let cname = 'googtrans';
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        lang = c.substring(name.length, c.length);
        //console.log('1', lang);
        lang = lang.split('/en/')[1];
      }
    }
    //console.log('2', lang);
    lang = this.storageService.getItem('lang');
    if (!lang) {
      lang = navigator.language ? navigator.language : navigator.languages[0];
      if (lang == 'en-US' || lang == 'en-GB') {
        lang = 'en';
      }
      let status = false;
      for (let i = 0; i < this.languages.length; i++) {
        if (lang == this.languages[i]['code']) {
          status = true;
          break;
        }
      }
      if (!status) {
        lang = '';
      }
    }
    let status = false;
    for (let i = 0; i < this.languages.length; i++) {
      if (lang == this.languages[i]['code']) {
        status = true;
        break;
      }
    }
    if (!status) {
      lang = '';
    }
    return lang;
  }
  // async getAdsData(data, callback) {
  //   let Ads_data = [];
  //   await this.getIP((err, result) => {
  //     //console.log("IP from if block:: ", this.ip);
  //     if (data.placements.length > 0) {
  //       let val: any = {
  //         placements: data.placements
  //       };
  //       if (this.ip) {
  //         val.ip = this.ip;
  //       }
  //       //console.log("val", val);
  //       fetch('https://e-' + Number(environment.kevel.NETWORK_ID) + '.adzerk.net/api/v2', {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(val)
  //       }).then((data) => {
  //         return data.json();
  //       }).then((json) => {
  //         data.allDiv.forEach(element => {
  //           //console.log("json.decisions ::", json.decisions[element]);
  //           if (json.decisions && json.decisions[element]) {
  //             Ads_data.push(json.decisions[element]);
  //           }
  //         });
  //         callback(null, { status: 0, message: "success", Ads_data: Ads_data });
  //       }).catch(error => {
  //         callback(error, null);
  //       });
  //     } else {
  //       callback(null, { status: 0, message: "success", Ads_data: Ads_data });
  //     }
  //   });
  // }
  // async getAdsData_API(data, callback) {
  //   let Ads_data = [];
  //   await this.getIP((err, result) => {
  //     //console.log("IP from if block:: ", this.ip);
  //     if (data.placements.length > 0) {
  //       let val: any = {
  //         placements: data.placements,
  //         siteId: data.placements[0].siteId,
  //         networkId: Number(environment.kevel.NETWORK_ID)
  //       };
  //       if (this.ip) {
  //         val.ip = this.ip;
  //       }
  //       //console.log("val", val);
  //       this.appService.postRequest(CONST_AWS_API.KEVEL_ADS, val).subscribe(resp => {
  //         data.allDiv.forEach(element => {
  //           // console.log(json.decisions[element]);

  //           if (resp.data.decisions && resp.data.decisions[element]) {
  //             resp.data.decisions[element].forEach(ele => {
  //               if (ele != null)
  //                 Ads_data.push(ele);
  //             });
  //           }
  //         });
  //         callback(null, { status: 0, message: "success", Ads_data: Ads_data });
  //       }, error => {
  //         callback(error, null);
  //       });
  //       // fetch('https://e-' + Number(environment.kevel.NETWORK_ID) + '.adzerk.net/api/v2', {
  //       //   method: 'POST',
  //       //   headers: {
  //       //     'Accept': 'application/json',
  //       //     'Content-Type': 'application/json'
  //       //   },
  //       //   body: JSON.stringify(val)
  //       // }).then((data) => {
  //       //   return data.json();
  //       // }).then((json) => {
  //       //   data.allDiv.forEach(element => {
  //       //     // console.log(json.decisions[element]);
  //       //     if (json.decisions && json.decisions[element]) {
  //       //       Ads_data.push(json.decisions[element]);
  //       //     }
  //       //   });
  //       //   callback(null, { status: 0, message: "success", Ads_data: Ads_data });
  //       // }).catch(error => {
  //       //   callback(error, null);
  //       // });
  //     } else {
  //       callback(null, { status: 0, message: "success", Ads_data: Ads_data });
  //     }
  //   });
  // }

  async getIP(callback) {
    fetch('https://api.ipify.org/?format=json').then((data) => {
      return data.json();
    }).then((json) => {
      if (json && json.ip) {
        this.ip = json.ip;
        callback(null, this.ip);
      }
      //console.log("getIP json", json);
    }).catch(error => {
      callback(error, null);
      console.log("getIP error", error);
    });;
  }

  isSubChannelPresent;
  settingsData: any = {};
  getWebPageSettings(id, channelName?) {
    if (this.icWebpageSettingsData && this.icWebpageSettingsData.channelInfo && (id != this.icWebpageSettingsData.channelInfo.channel_id)) {
      let type = channelName ? 'channel_name' : 'channel_id';
      let myInit = {
        headers: {
          'x-api-key': environment.AWS_Cognito.X_Api_Key
        },
        response: true,
        body: id != 0 ? {
          [type]: id,
          "organization_id": this.icWebpageSettingsData.channelInfo.org_id,
        } : {
          "organization_id": this.icWebpageSettingsData.channelInfo.org_id,
        }
      }
      AWS_API.post(environment.AWS_Cognito.apiName, CONST_AWS_API.GET_EVENT_WEBPAGE, myInit).then(response => {

        this.ngxLoader.stop();
        let settingsData: any = {};
        // let webSettings:any = (this.isSubChannelPresent && response.data.Data[0].template == 1 ) ? response.data.Data[0].settings : response.data.Data[0]
        let webSettings = response.data.Data ? response.data.Data[0] : {};
        if (this.router.url.includes('preview')) {
          if (webSettings && webSettings.webpage_settings_preview && Object.keys(webSettings.webpage_settings_preview).length > 0) {
            settingsData = JSON.parse(webSettings.webpage_settings_preview);
          } else {
            settingsData = this.defaultSettingsData;
          }
          settingsData.is_preview = true;
        } else {
          if (webSettings && webSettings.webpage_settings_publish && Object.keys(webSettings.webpage_settings_publish).length > 0) {
            settingsData = JSON.parse(webSettings.webpage_settings_publish);
          } else {
            settingsData = this.defaultSettingsData;
          }
          settingsData.is_preview = false;
        }

        if (settingsData.fs_main_title) {
          settingsData.fs_main_title = settingsData.fs_main_title + "px";
        }
        if (settingsData.fs_sentence) {
          settingsData.fs_sentence = settingsData.fs_sentence + "px";
        }
        if (settingsData.fs_sub_title) {
          settingsData.fs_sub_title = settingsData.fs_sub_title + "px";
        }

        if (settingsData.subs_fs_main_title) {
          settingsData.subs_fs_main_title = settingsData.subs_fs_main_title + "px";
        }
        if (settingsData.subs_fs_sentence) {
          settingsData.subs_fs_sentence = settingsData.subs_fs_sentence + "px";
        }
        if (settingsData.subs_fs_sub_title) {
          settingsData.subs_fs_sub_title = settingsData.subs_fs_sub_title + "px";
        }
        if (settingsData.form_fs_main_title) {
          settingsData.form_fs_main_title = settingsData.form_fs_main_title + "px";
        }
        if (settingsData.font_family) {
          let data: any = this.fontFamilyList.filter(a => a.id == settingsData.font_family);
          if (data && data.length > 0) {
            var fileref = document.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", data[0].link);
            document.getElementsByTagName("head")[0].appendChild(fileref);
            settingsData.font_family = data[0].code;
          }
        }
        if (Object.keys(settingsData).length == 0) {
          settingsData = this.defaultSettingsData;
        }
        let channel = {
          org_id: this.icWebpageSettingsData.channelInfo.org_id,
          channel_id: webSettings && webSettings.channel_id ? webSettings.channel_id : 0,
          channel_display_name: webSettings && webSettings.channel_display_name ? webSettings.channel_display_name : "",
          channel_name: webSettings && webSettings.channel_name ? webSettings.channel_name : "",
          subscription: webSettings && webSettings.subscription === 1 ? 1 : 0,
          template: webSettings && webSettings.template === 1 ? 1 : 0,
          channel_logo: webSettings && webSettings.logo ? webSettings.logo : ""
        }

        settingsData.channelInfo = channel;
        settingsData.banner_image = webSettings && webSettings.banner_image ? webSettings.banner_image : DEFAULT_IMAGE.IMAGE_2;
        this.sendICWebpageThemeSettings(settingsData);
        //console.log(settingsData)
        //console.log("settingsData", settingsData);
        this.storageService.setItem("channelName", this.router.url.split('/')[1])
        //console.log(localStorage.getItem("ThemelocalData"),)

      }).catch(error => {
        this.ngxLoader.stop();
        return false;
      });
    }
  }

  trackFBEvents(eventName, amount?) {
    if (eventName != 'Subscribe') {
      try { fbq('track', eventName); } catch (e) { };
    } else {
      try { fbq('track', 'Subscribe', amount); } catch (e) { }
    }
  }

  trackGoogleAnalytics(eventName, amount?) {
    if (eventName != 'purchase') {
      try {
        gtag("event", eventName);
      } catch (e) { }
    } else {
      try { gtag("event", eventName, amount); } catch (e) { }
    }
  }

  getProductsPrice(productsList, id, data, callback) {
    productsList.forEach(element => {

      // element.price_details = [{ currency_type: 'USD', price: '5.00' }, { currency_type: 'GBP', price: '5.99' }];
      if (element.id == id) {
        // if(element.id == 455){
        //   element.price_details = [{ currency_type: 'AED', price: '115.00' }, { currency_type: 'EUR', price: '54.99' }];
        // }else{
        //   element.price_details = [{ currency_type: 'USD', price: '5.00' }, { currency_type: 'GBP', price: '5.99' }];
        // }
        element.convertTo = false;
        let currencyVal = false;
        element.pricing = typeof element.pricing == 'object' ? element.pricing : JSON.parse(element.pricing);
        if (element.pricing && element.pricing.length > 0) {
          element.pricing.forEach(e => {
            if (data.currency && e.currency_type == data.currency && !currencyVal) {
              element.price = Number(e.price);
              element.currency_type = e.currency_type;
              currencyVal = true;
            } else if (data.currentLocation && data.currentLocation.currency && e.currency_type == data.currentLocation.currency.code && !currencyVal) {
              element.price = Number(e.price);
              element.currency_type = e.currency_type;
              currencyVal = true;
            }
            // else if (data.streamData && data.streamData.currency_type && e.currency_type == data.streamData.currency_type && !currencyVal) {
            //   element.price = Number(e.price);
            //   element.currency_type = e.currency_type;
            //   currencyVal = true;
            // } 
          })
          if (!element.price || !currencyVal) {
            element.price = Number(element.pricing[0].price);
            element.currency_type = data.currency;
            element.convertTo = true;
          }
        }
        callback(element);
      }
    })
  }

}
