const Ethereum = {
  SINGLE_NFT721_JSON: "../Contracts/Rinkeby/NFT.json",
  SINGLE_NFT721_ADDRESS: "0xFace719E9D7A199aeD97e406CEFDf0f5eA195fB0",
  MULTIPLE_NFT1155_JSON: "../MultNFT.json",
  MULTIPLE_NFT1155_ADDRESS: "0xFe049F9274D77c626f961195921aB1EEB313cE68",
  ERC_20_JSON: "../ERC20.json",
  ERC_20_ADDRESS: "0x2E95Cea14dd384429EB3c4331B776c4CFBB6FCD9",
  MARKET_PLACE_JSON: "../MarketPlace.json",
  MARKET_PLACE_ADDRESS: "0x36976bFE7592bb83c7D56265F528A68B5EBaFB97",
  MARKET_PLACE_1155_JSON: "../MarketPlace_1155.json",
  MARKET_PLACE_1155_ADDRESS: "0x34C7De3604e89E0879F98a70916d91baE5fc01DF",
  LAZY_MINT_JSON: "../LazyMint.json",
  LAZY_MINT_ADDRESS: "0xa64Fb7d36a2D6130788939166B1789D0Ec6C4940",
  BATCH_MINT_JSON: "../Batchmint.json",
  BATCH_MINT_ADDRESS: "0x53a668332B20689e7810451cd6cBe737D9F71201",
  BATCH_MINT_MARKETPLACE: "../BatchMarketPlace.json",
  BATCH_MINT_MARKETPLACE_ADDRESS: "0xbFaE970563E25042955E0D726465C6B09e87f028"

}

// const Contracts_Matic = {
//   SINGLE_NFT721_JSON: "../Contracts/Rinkeby/NFT.json",
//   SINGLE_NFT721_ADDRESS: "asdlkfnasdlfkas;ldkfmasd;lkfmasd;lfkmasd;lckasd;cklnasd'nas;dlfcknasdck/l",
//   MULTIPLE_NFT1155_JSON: "../MultNFT.json",
//   MULTIPLE_NFT1155_ADDRESS: "ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",
//   ERC_20_JSON: "",
//   ERC_20_ADDRESS: "sddddddddddddddddfasdfaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
//   MARKET_PLACE_JSON: "../MarketPlace.json",
//   MARKET_PLACE_ADDRESS: "dssssssssssssssssssssssssssssssssssssssssssssssssssssskkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
//   MARKET_PLACE_1155_JSON: "../MarketPlace_1155.json",
//   MARKET_PLACE_1155_ADDRESS: "0x968C74B1F515244141af5FDeEf2b851bC9f9af91",
//   LAZY_MINT_JSON: "../LazyMint.json",
//   LAZY_MINT_ADDRESS: "0xa64Fb7d36a2D6130788939166B1789D0Ec6C4940",
//   BATCH_MINT_JSON: "../Batchmint.json",
//   BATCH_MINT_ADDRESS: "0x53a668332B20689e7810451cd6cBe737D9F71201",
//   BATCH_MINT_MARKETPLACE: "../BatchMarketPlace.json",
//   BATCH_MINT_MARKETPLACE_ADDRESS: "0xbFaE970563E25042955E0D726465C6B09e87f028"
// }
// export const Contracts_Rinkeby = localStorage.getItem('networkName') ==  "Avalanche" ? Contracts_Matic : rinkebyJSON;

export const Ethereum_mainnet = Ethereum;
