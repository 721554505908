import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setItem(key: string, value): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  }

  getItem(key: string) {
    try {
      var value = localStorage.getItem(key) || undefined;
      if (value) {
        return JSON.parse(value);
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  }

  deleteItem(key: string) {
    localStorage.removeItem(key);
  }

  // clear() {
  //   localStorage.clear();
  // }

}