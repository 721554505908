// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: "dev1",
  ipfs: 'http://10.10.10.66',
  captchaKey: '6Le_aVgUAAAAAJ3p_Uwt4H6MlHSkhZL1t4J_zOH4',
  CONSUMER_PORTAL: "https://dev1.arevea.com/",
  API_URL_TV: 'https://nft.throne.market/',
  // API_URL_TV: 'http://localhost:8080/v1/',
  VENDOR_PORTAL: "https://dev1.arevea.com/creator/",
  AWS_FILE_BUCKET_PATH: 'https://dev-uploads.arevea.com/dev/',
  UPLOAD_URL_EXTENSION: '.com/',
  AWS_Cognito: {
    userPoolWebClientId: '1649c1cg31kftiv1p8dfmr8oqc',
    userPoolId: 'us-west-2_vzbJyOKf4',
    identityPoolId: 'us-west-2:2f173740-e6a4-4fc5-a37a-3064ac25e1bc',
    region: 'us-west-2',
    apiName: 'areveatv_dev_api',
    //apiBase: 'https://r5ibd3yzp7.execute-api.us-west-2.amazonaws.com/devel/',
    apiBase: 'https://dev1-apis.arevea.com/',
    // apiBase: 'http://localhost:3000/devel/',
    //apiBase: 'https://d-uujllnpgf1.execute-api.us-west-2.amazonaws.com/devel/',
    // region_cloudSearch: "us-west-2",
    // apiName_cloudSearch : "cloudSearch",
    // apiBase_CloudSearch : "https://r5ibd3yzp7.execute-api.us-west-2.amazonaws.com/devel/search",
    X_Api_Key: "ORnphwUvEBoqHaoIDBIA2GOhYF0HHQ53JPkLwFM5",
    aws_appsync_graphqlEndpoeint: "https://37g7lmpcpbbm7jblrdlaqfz5ci.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: 'us-west-2',
    aws_appsync_apiKey: 'da2-uozdgp6u6rainl2dl5v7ayzena',
    aws_appsync_apiID: '4wqw4untsbfzbjplwbrmnqsyry'
  },
  AWS: {
    RECORDED_VIDEO_PATH: "https://qa1-vod.arevea.com/live/",
    SignedURLBase: "https://areveatv-vod-dev-source-10dwb74hibmu3.s3.us-west-2.amazonaws.com/",
    AWS_ACCESS_KEY: "AKIA5YRJNZPVRAXMQIPM",
    AWS_SECRET_KEY: "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",
    BUCKET: "areveatv-vod-dev-source-10dwb74hibmu3",
    UPLOAD_BUCKET: "uploads-areveatv-dev",
    ACCESS_URL: "https://dev1-uploads.arevea.com/"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
