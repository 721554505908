import { Injectable } from '@angular/core';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { BehaviorSubject } from 'rxjs';

const SINGLE_NFT721_JSON = require('src/app/Contracts/Rinkeby/NFT.json');
const MULTIPLE_NFT1155_JSON = require('src/app/Contracts/Rinkeby/MultNFT.json');
const ERC_20_JSON = require('src/app/Contracts/Rinkeby/ERC20.json')

const MARKET_PLACE_JSON = require('src/app/Contracts/Rinkeby/MarketPlace.json')
const LAZY_MINT_JSON = require('src/app/Contracts/Rinkeby/LazyMint.json')
const BATCH_MINT_JSON = require('src/app/Contracts/Rinkeby/Batchmint.json')
const BATCH_MINT_MARKETPLACE_JSON = require('src/app/Contracts/Rinkeby/BatchMarketPlace.json')
const MARKET_PLACE_1155_JSON = require('src/app/Contracts/Rinkeby/MarketPlace_1155.json')

import { Ethereum_mainnet } from '../BlockChainInfo';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class Web3Service {

  private accountId = new BehaviorSubject('');
  connectedAccId = this.accountId.asObservable();

  private web3js: any;
  private provider: any;
  private accounts: any;
  web3Modal: any;
  balance: string | undefined;
  messageResult: string;
  walletcon: boolean = false;
  marketplace: any;
  network_name: any;
  erc721contract: any;
  erc1155contract: any;
  marketplacecontract: any;
  erc20contract: any;
  marketplacecontract_1155: any;
  lazymintcontract: any;
  batchmintcontract: any;
  batchmintmarketplacecontract: any;

  constructor(private appService: AppService) {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required | here whe import the package necessary to support wallets|| aqui importamos el paquete que nos ayudara a usar soportar distintas wallets
        options: {
          infuraId: 'env', // required change this with your own infura id | cambia esto con tu apikey de infura
          description: 'Scan the qr code and sign in', // You can change the desciption | Puedes camnbiar los textos descriptivos en la seccion description
          qrcodeModalOptions: {
            mobileLinks: [
              'rainbow',
              'metamask',
              'argent',
              'trust',
              'imtoken',
              'pillar'
            ]
          }
        }
      },
      injected: {
        display: {
          logo: 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg',
          name: 'metamask',
          description: "Connect with the provider in your Browser"
        },
        package: null
      },
    };

    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });
  }
  signout() {
    this.web3Modal.clearCachedProvider();
  }

  async connectAccount() {
    this.web3Modal.clearCachedProvider();

    this.provider = await this.web3Modal.connect(); // set provider
    console.log("Provider ", this.provider);
    console.log("Wallet connected to blockchain network Id: ", this.provider.networkVersion)
    // if (this.provider.networkVersion !== "4"){
    //   alert("Please select Rinkeby Test Network")
    //   return ""
    // }   
    if (this.provider) {
      this.web3js = new Web3(this.provider);
    } // create web3 instance
    const accounts = await this.web3js.eth.getAccounts();
    this.accounts = accounts[0]
    this.updateAccountAddress(this.accounts)
    console.log("Account Number", this.accounts);
    let balance = await this.accountInfo(this.accounts);
    console.log("Account Balance: ", balance)
    this.noOfUser()
    return this.accounts;
  }

  async intermidateConnect() {
    this.provider = await this.web3Modal.connect();//JSON.parse(localStorage.getItem('Set_Provider'));
    console.log('Set-Provider -> ', this.provider);
    if (this.provider) {
      this.web3js = new Web3(this.provider);
    } // create web3 instance
    const accounts = await this.web3js.eth.getAccounts();
    this.accounts = accounts[0];
    this.setContract();
    this.updateAccountAddress(this.accounts)
    console.log("Account Number", this.accounts);
    let balance = await this.accountInfo(this.accounts);
    console.log("Account Balance: ", balance)
    this.noOfUser()
    return this.accounts;
  }

  public getNetwork() {
    return this.network_name
  }

  public setNetwork(name) {
    this.network_name = name
  }

  async accountInfo(account: any[]) {
    const initialvalue = await this.web3js.eth.getBalance(account);
    this.balance = this.web3js.utils.fromWei(initialvalue, 'ether');
    return this.balance;
  }

  public checkAndInstantiateWeb3(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (window.ethereum) {
        // window.ethereum.on("accountsChanged", (accounts) => {
        //   this.accounts = accounts[0]
        //   this.updateAccountAddress(this.accounts)
        //   })
        this.messageResult = 'connected';
        window.web3 = new Web3(window.ethereum);
        // window.ethereum.enable().
        // resolve(this.messageResult);
        try {
          const accounts = window.ethereum.request({ method: 'eth_requestAccounts' });
          this.messageResult = 'connected';
          console.log("accounts in checkand Inst ", accounts)
          // this.accounts = accounts[0]
          // this.updateAccountAddress(this.accounts)
          resolve(this.messageResult)
          // setAccounts(accounts);
        } catch (error) {
          if (error.code === 4001) {
            alert("connect to web3")
          }

          // setError(error);
        }
      } else if (window.web3) {
        this.messageResult = 'connected';
        window.web3 = new Web3(window.web3.currentProvider);
        resolve(this.messageResult);
      } else {
        alert("No digital wallet detected. Install atleast Metamask")
        this.messageResult = 'No Ethereum browser detected. you should consider trying MetaMask';
        reject(this.messageResult);
      }
    });
  }

  public loadBlockChainData(): Promise<string> {
    return new Promise((resolve, reject) => {
      const web3 = window.web3;
      const account = web3.eth.getAccounts();
      // const account = window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log("account in loadblockchain data", this.accounts)
      if (this.accounts !== undefined) {
        resolve(this.accounts);
      } else {
        this.messageResult = 'There is no account';
        reject(this.messageResult);
      }
    });
  }

  public getEtherBalance(account) {

    return new Promise((resolve) => {
      const web3 = window.web3;
      const balance = web3.eth.getBalance(account)
        .then(ba => {
          resolve(web3.utils.fromWei(ba, 'Ether'));
        });
    });

  }
  public setContract() {
    var abi, networkAddress;
    var contract_nw;
    
    // if (this.getNetwork() == 'Ethereum Rinkeby') {
    //   contract_nw = Ethereum_mainnet
    // }
    if (this.getNetwork() == 'Ethereum Mainnet') {  
      contract_nw = Ethereum_mainnet
    }


    const web3 = new Web3(this.provider)

    abi = SINGLE_NFT721_JSON;
    networkAddress = contract_nw.SINGLE_NFT721_ADDRESS;
    this.erc721contract = new web3.eth.Contract(abi, networkAddress);

    abi = MULTIPLE_NFT1155_JSON;
    networkAddress = contract_nw.MULTIPLE_NFT1155_ADDRESS;
    this.erc1155contract = new web3.eth.Contract(abi, networkAddress);

    abi = MARKET_PLACE_JSON;
    networkAddress = contract_nw.MARKET_PLACE_ADDRESS;
    this.marketplacecontract = new web3.eth.Contract(abi, networkAddress);

    abi = ERC_20_JSON;
    networkAddress = contract_nw.ERC_20_ADDRESS;
    this.erc20contract = new web3.eth.Contract(abi, networkAddress);

    abi = MARKET_PLACE_1155_JSON;
    networkAddress = contract_nw.MARKET_PLACE_1155_ADDRESS
    this.marketplacecontract_1155 = new web3.eth.Contract(abi, networkAddress)

    abi = LAZY_MINT_JSON;
    networkAddress = contract_nw.LAZY_MINT_ADDRESS
    this.lazymintcontract = new web3.eth.Contract(abi, networkAddress)

    abi = BATCH_MINT_JSON;
    networkAddress = contract_nw.BATCH_MINT_ADDRESS
    this.batchmintcontract = new web3.eth.Contract(abi, networkAddress)

    abi = BATCH_MINT_MARKETPLACE_JSON;
    networkAddress = contract_nw.BATCH_MINT_MARKETPLACE_ADDRESS
    this.batchmintmarketplacecontract = new web3.eth.Contract(abi, networkAddress)

  }

  public getContract(contractType) {

    if (contractType == 'ERC20') {
      return this.erc20contract
    } else if (contractType == 'ERC721') {
      return this.erc721contract
    } else if (contractType == 'MarketPlace') {
      return this.marketplacecontract
    } else if (contractType == 'ERC1155') {
      return this.erc1155contract
    } else if (contractType == 'MarketPlace_1155') {
      return this.marketplacecontract_1155
    } else if (contractType == 'LAZYMINT') {
      return this.lazymintcontract
    } else if (contractType == 'BATCHMINT') {
      return this.batchmintcontract
    } else if (contractType == 'BATCHMINT_MarketPlace') {
      return this.batchmintmarketplacecontract
    }


  }

  // public getContract(contractType, network) {
  //   var abi;
  //   var networkAddress;
  //   var contract_nw;
  //   if (network == 'rinkeby'){
  //     contract_nw = Ethereum_mainnet
  //   }
  //   console.log("parameters in getcontract", contractType, network)
  //   return new Promise((resolve) => {
  //     const web3 = new Web3(this.provider)
  //     if (contractType === 'ERC721'){
  //       abi = SINGLE_NFT721_JSON;
  //       networkAddress = contract_nw.SINGLE_NFT721_ADDRESS;
  //     }else if(contractType == 'ERC1155'){
  //       abi = MULTIPLE_NFT1155_JSON;
  //       networkAddress = contract_nw.MULTIPLE_NFT1155_ADDRESS;
  //     }else if (contractType === 'MarketPlace'){
  //       abi = MARKET_PLACE_JSON;
  //       networkAddress = contract_nw.MARKET_PLACE_ADDRESS;
  //     }else if (contractType === 'ERC20'){
  //       abi = ERC_20_JSON;
  //       networkAddress = contract_nw.ERC_20_ADDRESS;
  //     }

  //     console.log("ABI is", abi);
  //     console.log("address is", networkAddress)
  //     const marketplace = new web3.eth.Contract(abi, networkAddress);

  //     resolve(marketplace);

  //   });
  // }

  // public getContract(contractType) {
  //   var abi;
  //   var networkAddress;
  //   var contract_nw;

  //   if (this.getNetwork() == 'Ethereum Mainnet'){
  //     contract_nw = Ethereum_mainnet
  //   }
  //   console.log("parameters in getcontract", contractType)

  //     const web3 = new Web3(this.provider)
  //     if (contractType === 'ERC721'){
  //       abi = SINGLE_NFT721_JSON;
  //       networkAddress = contract_nw.SINGLE_NFT721_ADDRESS;
  //     }else if(contractType == 'ERC1155'){
  //       abi = MULTIPLE_NFT1155_JSON;
  //       networkAddress = contract_nw.MULTIPLE_NFT1155_ADDRESS;
  //     }else if (contractType === 'MarketPlace'){
  //       abi = MARKET_PLACE_JSON;
  //       networkAddress = contract_nw.MARKET_PLACE_ADDRESS;
  //     }else if (contractType === 'ERC20'){
  //       abi = ERC_20_JSON;
  //       networkAddress = contract_nw.ERC_20_ADDRESS;
  //     }

  //     console.log("ABI is", abi);
  //     console.log("address is", networkAddress)
  //     const smartcontract = new web3.eth.Contract(abi, networkAddress);

  //     return smartcontract


  // }


  public async switchAccounts() {

    const walletAddress = await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {}
        }
      ]
    });
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return accounts[0]
    // return walletAddress
  }

  public convertPriceToEther(price) {
    // const web3 = new Web3();
    // return this.web3js.utils.toWei(price.toString(), 'Ether');
    return this.web3js.utils.toWei(price.toString(), 'Ether');

  }

  updateAccountAddress(account: any) {
    this.accountId.next(account)
  }

  noOfUser() {
    let formBody = {
      "account_name": "N/A",
      "address": this.accounts,
      "bio": "N/A",
      "personal_url": "N/A"
    };
    this.appService.adduser(formBody).subscribe((formBody) => {
      console.log(formBody)
    })
  }

  public getAccount() {
    console.log("In get account ", this.accounts)
    // if(this.accounts === undefined){
    //   this.checkAndInstantiateWeb3().then(isweb3 =>{
    //     if (isweb3 == 'connected'){
    //       console.log("inside getaccounts ", this.accounts)
    //       return this.accounts
    //     }
    //   })
    // }
    return this.accounts
  }
  // async onChangeNetworkmumbai() {
  //   const chainId = 80001 // Polygon Mainnetif (window.ethereum.networkVersion !== chainId) {
  //   try {
  //     await window.ethereum.request({
  //       method: 'wallet_switchEthereumChain',
  //       params: [{ chainId: Web3.utils.toHex(chainId) }]
  //     });
  //   } catch (err) {
  //     // This error code indicates that the chain has not been added to MetaMaskif (err.code === 4902) {
  //     await window.ethereum.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [
  //         {
  //           chainName: 'Polygon mumbai test net',
  //           chainId: Web3.utils.toHex(chainId),
  //           nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
  //           rpcUrls: ['https://polygon-rpc.com/']
  //         }
  //       ]
  //     });
  //   }
  // }

  async onChangeNetworkEthmainnet() {
    const chainId = 1 // Polygon Mainnetif (window.ethereum.networkVersion !== chainId) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainId) }]
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMaskif (err.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Ethereum Mainnet',
            chainId: Web3.utils.toHex(chainId),
            nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://mainnet.infura.io/v3/']
          }
        ]
      });
    }
  }



  public getprovider() {

    return this.provider;

  }
}
