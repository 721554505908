import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { US_ORIGIN } from '../constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  isConnected = true;

  reportData = new Subject<any>();
  currentPlayingAudio = new Subject<any>();
  currentPlayingVideo = new Subject<any>();
  currentPlayingAudioTime = new Subject<any>();
  currentPlayingVideoTime = new Subject<any>();

  constructor(private http: HttpClient, private connectionService: ConnectionService, private toastr: ToastrService) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
    });
  }

  getUrl(url): any {
    if (this.isConnected) {
      let apiUrl = url;
      return this.http.get(apiUrl);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  getRequest(url): any {
    if (this.isConnected) {
      let apiUrl = environment.API_URL_TV + url;
      return this.http.get(apiUrl);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  getRequestWithParams(url, data): any {
    if (this.isConnected) {
      let apiUrl = environment.API_URL_TV + url;
      return this.http.get(apiUrl, { params: data });
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postRequest(url, data): any {
    if (this.isConnected) {
      let apiUrl = environment.API_URL_TV + url;
      return this.http.post(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postCustomRequest(url, data, reqHeaders): any {
    if (this.isConnected) {
      const headers = new HttpHeaders(reqHeaders);
      let apiUrl = environment.API_URL_TV + url;
      return this.http.post(apiUrl, data, { headers: headers, responseType: 'blob' });
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postFormData(url, data) {
    if (this.isConnected) {
      let apiUrl = environment.API_URL_TV + url;
      return this.http.post(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postWithQuery(url, data) {
    if (this.isConnected) {
      let apiUrl = environment.API_URL_TV + url;
      return this.http.post(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postWithFile(url, data, files) {
    if (this.isConnected) {
      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].key, files[i].file);
      }
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          formData.append(property, data[property]);
        }
      }
      let apiUrl = environment.API_URL_TV + url;
      return this.http.post(apiUrl, formData);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  postWithFileLambda(url, data) {
    if (this.isConnected) {
      let apiUrl = environment.AWS_Cognito.apiBase + url;
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'x-api-key': environment.AWS_Cognito.X_Api_Key
      }
      return this.http.post(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }
  putWithFileS3(url, files) {
    if (this.isConnected) {
      //console.log("files.type :: ",files.type); 
      const formData: FormData = new FormData();
      formData.append("file", files);
      let apiUrl = url;
      // return this.http.put(apiUrl, files).pipe(map(res => res));
      const headers = new HttpHeaders({ 'Content-Type': files.type });
      const req = new HttpRequest('PUT', apiUrl, formData, { headers: headers, reportProgress: true, });
      return this.http.request(req);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  OL_getRequest(url): any {
    if (this.isConnected) {
      let apiUrl: any = "";
      if (this.checkURLs(url)) {
        // apiUrl = environment.oneLogin.baseURL + url;
      } else {
        // apiUrl = environment.oneLogin.subDomainURL + url;
      }
      return this.http.get(apiUrl);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  OL_PostRequest(url, data): any {
    if (this.isConnected) {
      let apiUrl: any = "";
      if (this.checkURLs(url)) {
        // apiUrl = environment.oneLogin.baseURL + url;
      } else {
        // apiUrl = environment.oneLogin.subDomainURL + url;
      }
      return this.http.post(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  OL_PutRequest(url, data): any {
    if (this.isConnected) {
      let apiUrl: any = "";
      if (this.checkURLs(url)) {
        // apiUrl = environment.oneLogin.baseURL + url;
      } else {
        // apiUrl = environment.oneLogin.subDomainURL + url;
      }
      return this.http.put(apiUrl, data);
    } else {
      this.toastr.error("Please check network connection");
    }
  }

  checkURLs(url) {
    let returnValue = false;
    US_ORIGIN.forEach(e => {
      if (url.indexOf(e) != -1)
        returnValue = true;
    });
    return returnValue;
  }

  getNFTJson(url) {
    console.log("URL in getNFTJSON ", url)
    const json = this.http.get('https://ipfs.io/ipfs/' + url);
    console.log("JSON in getNFT ", json)
    return json
  }

  addTransaction(formBody: any) {
    return this.http.post('https://nft.throne.market/api/addtran', formBody);
  }

  transactionHistory() {
    return this.http.get('https://nft.throne.market/api/getalltran?token_id=' + '68');
  }

  gernerateLazymintSign(formBody: any) {
    return this.http.post('https://nft.throne.market/api/addcol', formBody);
  }

  collectionID: any;
  set id(value: any) {
    this.collectionID = value;
  }

  lazymintNftdata() {
    return this.http.get('https://nft.throne.market/api/getcol?id=' + this.collectionID);
  }

  lazymintNftalldata() {
    return this.http.get('https://nft.throne.market/api/getallcol');
  }

  updateBuyNftData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/nftbuy', formBody);
  }

  accountAddress: any;
  set address(value: any) {
    this.accountAddress = value;
  }

  buyNftdata() {
    return this.http.get('https://nft.throne.market/api/getnftbuyinfo?account_address=' + localStorage.getItem('wallet address') + '&id=' + this.collectionID);
  }

  historybuyNftdata() {
    return this.http.get('https://nft.throne.market/api/getnftbuyinfo?account_address=' + localStorage.getItem('wallet address'));
  }

  purchaseHistoryData() {
    return this.http.get('https://nft.throne.market/api/purchasehistory?account_address=' + localStorage.getItem('wallet address'));
  }

  historylazymintNftdata() {
    return this.http.get('https://nft.throne.market/api/getcol?user_address=' + localStorage.getItem('wallet address'));
  }

  updatecreateNftData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/nft/createnft', formBody);
  }

  historyCreatenft() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?account_address=' + localStorage.getItem('wallet address'));
  }

  dataCreatenft() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?id=' + this.collectionID);
  }

  getNFTbyID() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?unique_id=' + localStorage.getItem('NFT unique id'));
  }

  buyNftdatabyID() {
    return this.http.get('https://nft.throne.market/api/getnftbuyinfo?unique_id=' + localStorage.getItem('NFT unique id'));
  }

  // FanSide post data

  updateAuctionHistoryData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/nftauchis', formBody);
  }

  updateNFTwalletHistoryData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/nftsalehis', formBody);
  }

  updateUserActivityData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/useractivity', formBody);
  }

  updateOfferMadeData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/offermade', formBody);
  }

  updateAuctionRecievedData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/offerrecieved', formBody);
  }

  updatenftListFanData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/wallet/fn/nftlist', formBody);
  }

  //Fanside get data


  auctionHistoryData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getnftauchis?id=' + this.collectionID);
  }

  nftWalletHistoryData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getwallethis?account_address=' + localStorage.getItem('wallet address'));
  }

  userActivityData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getuseractivity?account_address=' + localStorage.getItem('wallet address'));
  }

  offerMadeData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getoffermade?account_address=' + localStorage.getItem('wallet address'));
  }

  auctionRecievedData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getofferrecieved?account_address=' + localStorage.getItem('wallet address'));
  }

  nftListFanData() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getnftlist?account_address=' + localStorage.getItem('wallet address'));
  }




  updateSoldNFTData(formBody: any) {
    return this.http.post('localhost:5000/api/wallet/soldnft', formBody);
  }

  buyNftId: any;
  set tokenId(value: any) {
    this.buyNftId = value;
  }

  deleteBuyNft() {
    return this.http.delete('https://nft.throne.market/api/deletenft?token_id=' + this.buyNftId);
  }


  updateEachNftdata(formBody: any) {
    return this.http.post('https://nft.throne.market/api/nft/addauctionnft', formBody);
  }

  getTokenIDForEachNFT: any;
  set TokenIDForEachNFT(value: any) {
    this.getTokenIDForEachNFT = value;
  }

  getEachNftData() {
    return this.http.get('https://nft.throne.market/api/nft/getnftauction?token_id=' + this.getTokenIDForEachNFT);
  }

  offerMadeDataForEachNFT() {
    return this.http.get('https://nft.throne.market/api/wallet/fn/getoffermade?account_address=' + this.getTokenIDForEachNFT);
  }


  //superadmin

  adduser(formBody: any) {
    return this.http.post('https://nft.throne.market/api/user', formBody);
  }
  getalluser() {
    return this.http.get('https://nft.throne.market/api/user/getall')
  }

  reservePramotionNFTdata() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?sale_type=auction');
  }

  editionPramotionNFTdata() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts');
  }

  addEditionPromotionData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/nft/prom/create', formBody);
  }

  reservePromotionData() {
    return this.http.get('https://nft.throne.market/api/nft/prom/get?category=auction')
  }

  editionPromotionData() {
    return this.http.get('https://nft.throne.market/api/nft/prom/get?category=sale')
  }

  allPromotionData() {
    return this.http.get('https://nft.throne.market/api/nft/prom/getall')
  }

  removeEditionPromo(token_id) {
    return this.http.delete('https://nft.throne.market/api/nft/prom/delete?token_id=' + token_id);
  }

  blockUserAcc(address, boby) {
    return this.http.put('https://nft.throne.market/api/user/update/' + address, boby);
  }

  userAddress: any;
  set checkUserAddress(value: any) {
    this.userAddress = value;
  }

  checkBlockUser() {
    return this.http.get('https://nft.throne.market/api/user/getuser?address=' + this.userAddress)
  }

  selerAddress: any;
  set ReSalerAddress(value: any) {
    this.selerAddress = value;
  }

  reSaleTokenID: any;
  set reTokenID(value: any) {
    this.reSaleTokenID = value;
  }

  getResalecopies() {
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?account_address=' + this.selerAddress + '&token_id=' + this.reSaleTokenID)
  }

  batchMintData(formBody: any) {
    return this.http.post('https://nft.throne.market/api/nftbatch/createnftbatch', formBody);
  }


  updateNoOfCopies(unique_ID, boby) {
    return this.http.put('https://nft.throne.market/api/updatenftcopies/' + unique_ID, boby);
  }

  batchmint() {
    return this.http.get('https://nft.throne.market/api/nftbatch/getbatchmintnfts?id='+this.collectionID)
  }

  batchmintForbuy(batch_id) {
    return this.http.get('https://nft.throne.market/api/nftbatch/getbatchmintnfts?batch_id='+batch_id)
  }

  checkcopiesforresale() {
    return this.http.get('https://nft.throne.market/api/getnftbuyinfo?unique_id=' + localStorage.getItem('NFT unique id'));
  }

  updatebatchID(boby) {
    return this.http.put('https://nft.throne.market/api/nftbatch/updatebatchid/', boby);
  }

  allCreateNFTMultiple(){
    return this.http.get('https://nft.throne.market/api/nft/getcreatednfts?sale_type=sale&nft_type=multiple')
  }

}
